import 'babel-polyfill'
import main from './main';
import App from './js/App.js';
import FontFaceObserver from 'fontfaceobserver'
require('viewport-units-buggyfill').init();
main();

document.addEventListener("DOMContentLoaded", () => {
  const fontA = new FontFaceObserver('AkkuratStd-Regular');
  const fontB = new FontFaceObserver('AkkuratStd-Bold');
  const fontC = new FontFaceObserver('Mondwest-Regular');

  Promise.all([fontA.load(), fontB.load(), fontC.load()]).then(function() {
    App.init();
  }).catch(function() {
    console.log('Font failed to load.');
  });
});
