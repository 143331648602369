import LazySizes from './components/Lazysizes';
import Loader from './components/Loader';
import Routes from './components/Routes';
import Links from './components/Links';
import Countdown from './components/Countdown';
import TimeLeft from './components/TimeLeft';
import ResponsiveVideo from './components/ResponsiveVideo';
// import PageLoader from './components/PageLoader';
import debounce from 'lodash/debounce';
const App = {
    root: window.location.hostname == 'localhost' ? '/xxx/www' : '',
    // teaser, signup, signup|signin
    // mode: "teaser",
    init: _ => {
        App.mode = document.documentElement.dataset.mode
        App.redirectUrl = 'aAB0AHQAcABzADoALwAvAGUAdAB1AGQAZQBzAC0AcwB0AHUAZABpAG8ALgBjAG8AbQAvAGEAcgBjAGgAaQB2AGUAcwAtAGQAYQB5AHMA'
        Loader.init();
        Routes.init();
        App.sizeSet();
        App.interact()
        Loader.loaded()
        // PageLoader.init();
        window.addEventListener('resize', debounce(App.sizeSet, 300), false)
    },
    sizeSet: _ => {
        App.width = (window.innerWidth || document.documentElement.clientWidth)
        App.height = (window.innerHeight || document.documentElement.clientHeight)
        App.isMobile = App.width <= 1024
    },
    addListener: (target, eventType, func = () => {}) => {
        const targets = document.querySelectorAll('[event-target="' + target + '"]');
        [...targets].forEach(elem => {
            elem.addEventListener(eventType, func)
        })
    },
    interact: _ => {
        Links.init();
        Countdown.init();
        TimeLeft.init();
        ResponsiveVideo.init();
    },
    decryptString: (encryptedBase64) => {
        const base64ToArrayBuffer = (base64) => {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }
        const arrayBufferToString = (buff) => {
            return String.fromCharCode.apply(null, new Uint16Array(buff));
        }
        const arrBuffer = base64ToArrayBuffer(encryptedBase64)
        return arrayBufferToString(arrBuffer);
    },
    encryptString: (str) => {
        const arrayBufferToBase64 = (buffer) => {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        }
        const buff = new ArrayBuffer(str.length * 2) // Because there are 2 bytes for each char.
        const buffView = new Uint16Array(buff);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            buffView[i] = str.charCodeAt(i);
        }
        return arrayBufferToBase64(buff);
    }
};
export default App;
