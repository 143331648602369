import moment from "moment-timezone";
import Routes from "./Routes";

const Countdown = {
  time: "2024-11-21 08:30:00",
  init: () => {
    Countdown.node = document.querySelector("#countdown");
    if (!Countdown.node) return;
    Countdown.eventTime = moment.tz(Countdown.time, "Europe/Paris").unix();
    Countdown.currentTime = moment().unix();
    Countdown.diffTime = Countdown.eventTime - Countdown.currentTime;
    Countdown.duration = moment.duration(
      Countdown.diffTime * 1000,
      "milliseconds"
    );
    Countdown.interval = 1000;
    if (Countdown.diffTime > 0) {
      Countdown.run();
      if (Countdown.intervalInstance)
        window.clearInterval(Countdown.intervalInstance);
      Countdown.intervalInstance = setInterval(
        Countdown.run,
        Countdown.interval
      );
    } else {
      Countdown.finished = true;
      Routes.router.navigate("/welcome");
    }
  },
  run: () => {
    if (!Countdown.node) return;
    Countdown.duration = moment.duration(
      Countdown.duration.asMilliseconds() - Countdown.interval,
      "milliseconds"
    );
    var d = moment.duration(Countdown.duration).days(),
      h = moment.duration(Countdown.duration).hours(),
      m = moment.duration(Countdown.duration).minutes(),
      s = moment.duration(Countdown.duration).seconds();
    // let c = `${Countdown.format(d)}D ${Countdown.format(h)}H ${Countdown.format(m)}M ${Countdown.format(s)}S`;
    let c = `${
      d
        ? `<span class="number">${Countdown.format(d)}:<sup>Days</sup></span>`
        : ""
    }${`<span class="number">${Countdown.format(
      h
    )}:<sup>Hours</sup></span>`}${`<span class="number">${Countdown.format(
      m
    )}:<sup>Minutes</sup></span>`}${`<span class="number">${Countdown.format(
      s
    )}<sup>Seconds</sup></span>`}`;
    Countdown.node.innerHTML = c;

    Countdown.eventTime = moment.tz(Countdown.time, "Europe/Paris").unix();
    Countdown.currentTime = moment().unix();
    Countdown.diffTime = Countdown.eventTime - Countdown.currentTime;

    if (Countdown.diffTime > 0) {
    } else {
      Countdown.finished = true;
      Routes.router.navigate("/welcome");
    }
  },
  format: (n) => {
    return ("0" + n).slice(-2);
  },
};
export default Countdown;
