import App from "../App";
import Countdown from "./Countdown";
import TimeLeft from "./TimeLeft";
import axios from "axios";
import Navigo from "navigo";
const Routes = {
  mode: "archives-off",
  init: () => {
    const cookieName = "ETUDES-COOKIE";
    ///////////
    // UTILS //
    ///////////
    const parseQueryString = (query) => {
      if (!query) {
        return {};
      }
      return (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          const item = param.split("=");
          const key = decodeURIComponent(item[0] || "");
          const value = decodeURIComponent(item[1] || "");
          if (key) {
            params[key] = value;
          }
          return params;
        }, {});
    };
    ////////////////////////
    // Long shared styles //
    ////////////////////////
    const inputStyles = "form-input ff-bold block";
    const buttonStyles = "ff-text fs-large white uppercase primary-hover";
    const loadingButton = `<div class="loading ff-bold uppercase" style="display: none">Loading…</div>`;
    //////////////////////////
    // Shared ui behaviours //
    //////////////////////////
    const setLoading = (e) => {
      // e.target.querySelector(".loading").style.display = "inline-block";
      e.target.style.opacity = 0.5;
      const errorPlaceholder = e.target.querySelector(".errors");
      if (errorPlaceholder) {
        // errorPlaceholder.style.display = "none";
        errorPlaceholder.innerHTML = "&nbsp;";
      }
    };
    const unsetLoading = (e) => {
      // e.target.querySelector(".loading").style.display = "none"
      e.target.style.opacity = 1;
    };
    const setError = (e, message = null) => {
      unsetLoading(e);
      console.log(e);
      e.target.classList.add("error");
      const errorPlaceholder = e.target.querySelector(".errors");
      if (errorPlaceholder) {
        errorPlaceholder.style.display = "block";
        errorPlaceholder.innerHTML = message;
      }
    };
    //////////////
    // Ui parts //
    //////////////
    const buttonIcon = `
          <svg version="1.1" class="button-icon" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1000 1085.3" xml:space="preserve"><path class="st0" d="M0 583l77.2-75.6L500.8 931l423.6-423.6 75.6 77.1-499.2 500.8z"/><path class="st0" d="M0 75.6L77.2 0l423.6 423.6L924.4 0l75.6 77.2-499.2 500.7z"/></svg>
        `;
    const header = `
          <header>
            <a href="/" id="site-title" class="fs-large ff-pixel" data-router="home">Études<br>Archives Days</a>
            ${
              Routes.mode !== "archives-off"
                ? `<a href="/" id="countdown" class="fs-large ff-text white uppercase hidden" data-router="home"></a>`
                : '<div class="fs-large ff-text white uppercase">Sign up</div>'
            }
          </header>
        `;
    const links = `
          <div class="">
            <a href='/#/signin' id='signinLink' class="ff-bold uppercase">Signin</a>
            <a href='/#/signup' id='signupLink' class="ff-bold uppercase">Signup</a>
            <a href='/#/password' id='passwordLink' class="ff-bold uppercase">Password</a>
          </div>
        `;
    const handleLinks = (router) => {
      const homeLinks = document.querySelectorAll("[data-router='home']");
      const signupLink = document.querySelector("#signupLink");
      const signupLinks = document.querySelectorAll("[data-router='signup']");
      const signinLink = document.querySelector("#signinLink");
      const passwordLink = document.querySelector("#passwordLink");
      const logoutLink = document.querySelector("#logout");
      if (homeLinks) {
        homeLinks.forEach((el) => {
          el.addEventListener(
            "click",
            (e) => {
              e.preventDefault();
              router.navigate("/");
            },
            {
              once: true,
            }
          );
        });
      }
      if (signupLink) {
        signupLink.addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            router.navigate("/signup");
          },
          {
            once: true,
          }
        );
      }
      if (signupLinks) {
        signupLinks.forEach((el) => {
          el.addEventListener(
            "click",
            (e) => {
              e.preventDefault();
              router.navigate("/signup");
            },
            {
              once: true,
            }
          );
        });
      }
      if (signinLink) {
        signinLink.addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            router.navigate("/signin");
          },
          {
            once: true,
          }
        );
      }
      if (passwordLink) {
        passwordLink.addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            router.navigate("/password");
          },
          {
            once: true,
          }
        );
      }
      if (logoutLink) {
        logoutLink.addEventListener(
          "click",
          (e) => {
            e.preventDefault();
            Cookies.remove(cookieName, {
              path: "/",
            });
            router.navigate("/");
          },
          {
            once: true,
          }
        );
      }
    };
    ////////////
    // Teaser //
    ////////////
    const teaserTemplate = `
          <div id="background-video" class="video-is-muted">
            <video
            class="lazy landscape"
            data-poster="https://i.vimeocdn.com/video/997260152.webp?mw=3800&mh=2138&q=70"
            data-hd="https://player.vimeo.com/external/480668690.hd.mp4?s=7b566c9a98111dd23103b1ae4188720ea208bce3&profile_id=175"
            data-sd="https://player.vimeo.com/external/480668690.sd.mp4?s=45817f880eb15cb96be6f62c10b1e0ea094d6497&profile_id=164"
            playsinline autoplay muted loop preload="auto"></video>
            <video
            class="lazy portrait"
            data-poster="https://i.vimeocdn.com/video/997231481.webp?mw=1700&mh=2125&q=70"
            data-hd="https://player.vimeo.com/external/480668689.hd.mp4?s=e06076c845e90824e3461a5a23983c9b6bb1135f&profile_id=175"
            data-sd="https://player.vimeo.com/external/480668689.hd.mp4?s=e06076c845e90824e3461a5a23983c9b6bb1135f&profile_id=174"
            playsinline autoplay muted loop preload="auto"></video>
          </div>
        `;
    const teaserWelcomeTemplate = `
          <div id="background-video" class="video-is-muted no-ui">
            <video
            class="lazy"
            data-poster="https://i.vimeocdn.com/video/1007749598.webp?mw=3600&mh=2025&q=70"
            data-hd="https://player.vimeo.com/external/486920696.hd.mp4?s=f2d5d9c114783e76d7a22b5f3b403ce082825906&profile_id=175"
            data-sd="https://player.vimeo.com/external/486920696.sd.mp4?s=306edb0a94dcb8bd64a7ee55e72ef9795f634600&profile_id=165"
            playsinline autoplay muted loop preload="auto"></video>
          </div>
        `;
    ////////////
    // DEFAULT //
    ////////////
    const defaultTemplate = `
          <header class="fit-height">
            <div id="site-title" class="fs-large ff-pixel">Études<br>Archives Days</div>
            <div id="countdown" class="fs-large ff-text white uppercase"></div>
            <div class="spacer"></div>
            <div>
              <a href="/#/signup" id='signupLink' class="fs-large ff-text white uppercase primary-hover">Subscribe Now${buttonIcon}</a>
              ${
                App.mode == "signup|signin"
                  ? `<a href="/#/signin" id='signinLink' class="fs-large ff-text white uppercase primary-hover">Login${buttonIcon}</a>`
                  : ""
              }
            </div>
          </header>
        `;
    const defaultTemplateOff = `
          <header class="fit-height">
            <div id="site-title" class="fs-large ff-pixel">Études<br>Archives Days</div>
            <div id="message" class="fs-large ff-text white uppercase">is offline</div>
            <div class="fs-large ff-pixel" data-router="signup">Sign up to stay tuned<br>for the next event</div>
          </header>
        `;
    ////////////
    // SIGNUP //
    ////////////
    const signupTemplate = `
          ${header}
          <form class="form" id="signup">
            <div class="form-fields">
              <div>
                <label class="block">First name</label>
                <input name="firstname" type="text" class="firstname ${inputStyles}" placeholder="…" autocomplete="given-name" required />
              </div>
              <div>
                <label class="block">Last name</label>
                <input name="lastname" type="text" class="lastname ${inputStyles}" placeholder="…" autocomplete="family-name" required />
              </div>
              <div>
                <label class="block">Email address</label>
                <input name="email" type="email" class="email ${inputStyles}" placeholder="…" autocomplete="email" required />
              </div>
              <div>
                <label class="block">Country</label>
                <select id="country" class="country ${inputStyles}" name="country" autocomplete="country-name" required>
                   <option value="">…</option>
                   <option value="Afganistan">Afghanistan</option>
                   <option value="Albania">Albania</option>
                   <option value="Algeria">Algeria</option>
                   <option value="American Samoa">American Samoa</option>
                   <option value="Andorra">Andorra</option>
                   <option value="Angola">Angola</option>
                   <option value="Anguilla">Anguilla</option>
                   <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                   <option value="Argentina">Argentina</option>
                   <option value="Armenia">Armenia</option>
                   <option value="Aruba">Aruba</option>
                   <option value="Australia">Australia</option>
                   <option value="Austria">Austria</option>
                   <option value="Azerbaijan">Azerbaijan</option>
                   <option value="Bahamas">Bahamas</option>
                   <option value="Bahrain">Bahrain</option>
                   <option value="Bangladesh">Bangladesh</option>
                   <option value="Barbados">Barbados</option>
                   <option value="Belarus">Belarus</option>
                   <option value="Belgium">Belgium</option>
                   <option value="Belize">Belize</option>
                   <option value="Benin">Benin</option>
                   <option value="Bermuda">Bermuda</option>
                   <option value="Bhutan">Bhutan</option>
                   <option value="Bolivia">Bolivia</option>
                   <option value="Bonaire">Bonaire</option>
                   <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                   <option value="Botswana">Botswana</option>
                   <option value="Brazil">Brazil</option>
                   <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                   <option value="Brunei">Brunei</option>
                   <option value="Bulgaria">Bulgaria</option>
                   <option value="Burkina Faso">Burkina Faso</option>
                   <option value="Burundi">Burundi</option>
                   <option value="Cambodia">Cambodia</option>
                   <option value="Cameroon">Cameroon</option>
                   <option value="Canada">Canada</option>
                   <option value="Canary Islands">Canary Islands</option>
                   <option value="Cape Verde">Cape Verde</option>
                   <option value="Cayman Islands">Cayman Islands</option>
                   <option value="Central African Republic">Central African Republic</option>
                   <option value="Chad">Chad</option>
                   <option value="Channel Islands">Channel Islands</option>
                   <option value="Chile">Chile</option>
                   <option value="China">China</option>
                   <option value="Christmas Island">Christmas Island</option>
                   <option value="Cocos Island">Cocos Island</option>
                   <option value="Colombia">Colombia</option>
                   <option value="Comoros">Comoros</option>
                   <option value="Congo">Congo</option>
                   <option value="Cook Islands">Cook Islands</option>
                   <option value="Costa Rica">Costa Rica</option>
                   <option value="Cote DIvoire">Cote DIvoire</option>
                   <option value="Croatia">Croatia</option>
                   <option value="Cuba">Cuba</option>
                   <option value="Curaco">Curacao</option>
                   <option value="Cyprus">Cyprus</option>
                   <option value="Czech Republic">Czech Republic</option>
                   <option value="Denmark">Denmark</option>
                   <option value="Djibouti">Djibouti</option>
                   <option value="Dominica">Dominica</option>
                   <option value="Dominican Republic">Dominican Republic</option>
                   <option value="East Timor">East Timor</option>
                   <option value="Ecuador">Ecuador</option>
                   <option value="Egypt">Egypt</option>
                   <option value="El Salvador">El Salvador</option>
                   <option value="Equatorial Guinea">Equatorial Guinea</option>
                   <option value="Eritrea">Eritrea</option>
                   <option value="Estonia">Estonia</option>
                   <option value="Ethiopia">Ethiopia</option>
                   <option value="Falkland Islands">Falkland Islands</option>
                   <option value="Faroe Islands">Faroe Islands</option>
                   <option value="Fiji">Fiji</option>
                   <option value="Finland">Finland</option>
                   <option value="France">France</option>
                   <option value="French Guiana">French Guiana</option>
                   <option value="French Polynesia">French Polynesia</option>
                   <option value="French Southern Ter">French Southern Ter</option>
                   <option value="Gabon">Gabon</option>
                   <option value="Gambia">Gambia</option>
                   <option value="Georgia">Georgia</option>
                   <option value="Germany">Germany</option>
                   <option value="Ghana">Ghana</option>
                   <option value="Gibraltar">Gibraltar</option>
                   <option value="Great Britain">Great Britain</option>
                   <option value="Greece">Greece</option>
                   <option value="Greenland">Greenland</option>
                   <option value="Grenada">Grenada</option>
                   <option value="Guadeloupe">Guadeloupe</option>
                   <option value="Guam">Guam</option>
                   <option value="Guatemala">Guatemala</option>
                   <option value="Guinea">Guinea</option>
                   <option value="Guyana">Guyana</option>
                   <option value="Haiti">Haiti</option>
                   <option value="Hawaii">Hawaii</option>
                   <option value="Honduras">Honduras</option>
                   <option value="Hong Kong">Hong Kong</option>
                   <option value="Hungary">Hungary</option>
                   <option value="Iceland">Iceland</option>
                   <option value="Indonesia">Indonesia</option>
                   <option value="India">India</option>
                   <option value="Iran">Iran</option>
                   <option value="Iraq">Iraq</option>
                   <option value="Ireland">Ireland</option>
                   <option value="Isle of Man">Isle of Man</option>
                   <option value="Israel">Israel</option>
                   <option value="Italy">Italy</option>
                   <option value="Jamaica">Jamaica</option>
                   <option value="Japan">Japan</option>
                   <option value="Jordan">Jordan</option>
                   <option value="Kazakhstan">Kazakhstan</option>
                   <option value="Kenya">Kenya</option>
                   <option value="Kiribati">Kiribati</option>
                   <option value="Korea North">Korea North</option>
                   <option value="Korea Sout">Korea South</option>
                   <option value="Kuwait">Kuwait</option>
                   <option value="Kyrgyzstan">Kyrgyzstan</option>
                   <option value="Laos">Laos</option>
                   <option value="Latvia">Latvia</option>
                   <option value="Lebanon">Lebanon</option>
                   <option value="Lesotho">Lesotho</option>
                   <option value="Liberia">Liberia</option>
                   <option value="Libya">Libya</option>
                   <option value="Liechtenstein">Liechtenstein</option>
                   <option value="Lithuania">Lithuania</option>
                   <option value="Luxembourg">Luxembourg</option>
                   <option value="Macau">Macau</option>
                   <option value="Macedonia">Macedonia</option>
                   <option value="Madagascar">Madagascar</option>
                   <option value="Malaysia">Malaysia</option>
                   <option value="Malawi">Malawi</option>
                   <option value="Maldives">Maldives</option>
                   <option value="Mali">Mali</option>
                   <option value="Malta">Malta</option>
                   <option value="Marshall Islands">Marshall Islands</option>
                   <option value="Martinique">Martinique</option>
                   <option value="Mauritania">Mauritania</option>
                   <option value="Mauritius">Mauritius</option>
                   <option value="Mayotte">Mayotte</option>
                   <option value="Mexico">Mexico</option>
                   <option value="Midway Islands">Midway Islands</option>
                   <option value="Moldova">Moldova</option>
                   <option value="Monaco">Monaco</option>
                   <option value="Mongolia">Mongolia</option>
                   <option value="Montserrat">Montserrat</option>
                   <option value="Morocco">Morocco</option>
                   <option value="Mozambique">Mozambique</option>
                   <option value="Myanmar">Myanmar</option>
                   <option value="Nambia">Nambia</option>
                   <option value="Nauru">Nauru</option>
                   <option value="Nepal">Nepal</option>
                   <option value="Netherland Antilles">Netherland Antilles</option>
                   <option value="Netherlands">Netherlands (Holland, Europe)</option>
                   <option value="Nevis">Nevis</option>
                   <option value="New Caledonia">New Caledonia</option>
                   <option value="New Zealand">New Zealand</option>
                   <option value="Nicaragua">Nicaragua</option>
                   <option value="Niger">Niger</option>
                   <option value="Nigeria">Nigeria</option>
                   <option value="Niue">Niue</option>
                   <option value="Norfolk Island">Norfolk Island</option>
                   <option value="Norway">Norway</option>
                   <option value="Oman">Oman</option>
                   <option value="Pakistan">Pakistan</option>
                   <option value="Palau Island">Palau Island</option>
                   <option value="Palestine">Palestine</option>
                   <option value="Panama">Panama</option>
                   <option value="Papua New Guinea">Papua New Guinea</option>
                   <option value="Paraguay">Paraguay</option>
                   <option value="Peru">Peru</option>
                   <option value="Phillipines">Philippines</option>
                   <option value="Pitcairn Island">Pitcairn Island</option>
                   <option value="Poland">Poland</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Puerto Rico">Puerto Rico</option>
                   <option value="Qatar">Qatar</option>
                   <option value="Republic of Montenegro">Republic of Montenegro</option>
                   <option value="Republic of Serbia">Republic of Serbia</option>
                   <option value="Reunion">Reunion</option>
                   <option value="Romania">Romania</option>
                   <option value="Russia">Russia</option>
                   <option value="Rwanda">Rwanda</option>
                   <option value="St Barthelemy">St Barthelemy</option>
                   <option value="St Eustatius">St Eustatius</option>
                   <option value="St Helena">St Helena</option>
                   <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                   <option value="St Lucia">St Lucia</option>
                   <option value="St Maarten">St Maarten</option>
                   <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                   <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                   <option value="Saipan">Saipan</option>
                   <option value="Samoa">Samoa</option>
                   <option value="Samoa American">Samoa American</option>
                   <option value="San Marino">San Marino</option>
                   <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                   <option value="Saudi Arabia">Saudi Arabia</option>
                   <option value="Senegal">Senegal</option>
                   <option value="Seychelles">Seychelles</option>
                   <option value="Sierra Leone">Sierra Leone</option>
                   <option value="Singapore">Singapore</option>
                   <option value="Slovakia">Slovakia</option>
                   <option value="Slovenia">Slovenia</option>
                   <option value="Solomon Islands">Solomon Islands</option>
                   <option value="Somalia">Somalia</option>
                   <option value="South Africa">South Africa</option>
                   <option value="Spain">Spain</option>
                   <option value="Sri Lanka">Sri Lanka</option>
                   <option value="Sudan">Sudan</option>
                   <option value="Suriname">Suriname</option>
                   <option value="Swaziland">Swaziland</option>
                   <option value="Sweden">Sweden</option>
                   <option value="Switzerland">Switzerland</option>
                   <option value="Syria">Syria</option>
                   <option value="Tahiti">Tahiti</option>
                   <option value="Taiwan">Taiwan</option>
                   <option value="Tajikistan">Tajikistan</option>
                   <option value="Tanzania">Tanzania</option>
                   <option value="Thailand">Thailand</option>
                   <option value="Togo">Togo</option>
                   <option value="Tokelau">Tokelau</option>
                   <option value="Tonga">Tonga</option>
                   <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                   <option value="Tunisia">Tunisia</option>
                   <option value="Turkey">Turkey</option>
                   <option value="Turkmenistan">Turkmenistan</option>
                   <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                   <option value="Tuvalu">Tuvalu</option>
                   <option value="Uganda">Uganda</option>
                   <option value="United Kingdom">United Kingdom</option>
                   <option value="Ukraine">Ukraine</option>
                   <option value="United Arab Erimates">United Arab Emirates</option>
                   <option value="United States of America">United States of America</option>
                   <option value="Uraguay">Uruguay</option>
                   <option value="Uzbekistan">Uzbekistan</option>
                   <option value="Vanuatu">Vanuatu</option>
                   <option value="Vatican City State">Vatican City State</option>
                   <option value="Venezuela">Venezuela</option>
                   <option value="Vietnam">Vietnam</option>
                   <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                   <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                   <option value="Wake Island">Wake Island</option>
                   <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                   <option value="Yemen">Yemen</option>
                   <option value="Zaire">Zaire</option>
                   <option value="Zambia">Zambia</option>
                   <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>
            </div>
            <div class="errors lh-text">&nbsp;</div>
            <div class="px-p pb-p">
              <button type="submit" class="${buttonStyles}">Ok</button>
            </div>
          </form>
        `;
    const signup = (e, router) => {
      e.preventDefault();
      const firstname = e.target.querySelector(".firstname").value;
      const lastname = e.target.querySelector(".lastname").value;
      const email = e.target.querySelector(".email").value;
      const country = e.target.querySelector(".country").value;
      setLoading(e);
      axios
        .post("/api/database/signup", {
          firstname,
          lastname,
          email,
          country,
        })
        .then(({ data }) => {
          unsetLoading(e);
          e.target.innerHTML = `<div class="px-p fs-large ff-pixel" data-router='home'>Thank you!</div>`;
        })
        .catch((err) =>
          setError(
            e,
            "This e-mail already exists or an unexpected error occurred."
          )
        );
    };
    ////////////
    // SIGNIN //
    ////////////
    const signinTemplate = `
          ${header}
          <form class="form" id="signin">
            <div class="form-fields">
              <div>
                <label class="block">Email address</label>
                <input name="email" type="email" class="email ${inputStyles}" placeholder="…" autocomplete="email" required />
              </div>
              <div>
                <label class="block">Password</label>
                <input name="password" type="password" class="password ${inputStyles}" placeholder="…" autocomplete="current-password" required />
              </div>
            </div>
            <div class="errors lh-text">&nbsp;</div>
            <div class="px-p pb-p">
              <button type="submit" class="${buttonStyles}">Login${buttonIcon}</button>
            </div>
          </form>
        `;
    const signin = (e, router) => {
      e.preventDefault();
      const email = e.target.querySelector(".email").value;
      const password = e.target.querySelector(".password").value;
      setLoading(e);
      axios
        .post("/api/database/signin", {
          email,
          password,
        })
        .then(({ data }) => {
          Cookies.set(cookieName, data.key, {
            path: "/",
          });
          router.navigate("/authenticated");
        })
        .catch((err) =>
          setError(
            e,
            "Invalid credentials. Please try again or <a href='/#/password' class='underline'>recover password</a>."
          )
        );
    };
    ///////////////////
    // PASSWORD INIT //
    ///////////////////
    const passwordInitTemplate = `
          ${header}
          <form class="form" id="passwordInit">
            <div class="form-fields">
              <div>
                <label class="block">Email address</label>
                <input name="email" type="email" class="email ${inputStyles}" placeholder="…" autocomplete="email" required />
              </div>
            </div>
            <div class="errors lh-text">&nbsp;</div>
            <div class="px-p pb-p">
              <button type="submit" class="${buttonStyles}">Submit${buttonIcon}</button>
            </div>
          </form>
        `;
    const passwordInit = (e) => {
      e.preventDefault();
      const email = e.target.querySelector(".email").value;
      setLoading(e);
      axios
        .post("/api/database/resetPasswordInit", {
          email,
        })
        .then(({ data }) => {
          unsetLoading(e);
          e.target.innerHTML = `<div class="px-p ff-bold uppercase">An email has been sent to ${email}</div>`;
        })
        .catch((err) => setError(e, "Invalid email"));
    };
    //////////////////////
    // PASSWORD CONFIRM //
    //////////////////////
    const passwordConfirmTemplate = (code) => `
          ${header}
          <form class="form" id="passwordConfirm">
            <div class="form-fields">
              <div>
                <label class="block">Define a new password</label>
                <input name="password" type="text" class="newPassword ${inputStyles}" placeholder="…" autocomplete="new-password" required />
              </div>
              <input type="hidden" name="code" class="code" value="${code}" />
            </div>
            <div class="errors lh-text">&nbsp;</div>
            <div class="px-p pb-p">
              <button type="submit" class="${buttonStyles}">Confirm${buttonIcon}</button>
            </div>
          </form>
        `;
    const passwordConfirm = (e) => {
      e.preventDefault();
      const newPassword = e.target.querySelector(".newPassword").value;
      const code = e.target.querySelector(".code").value;
      setLoading(e);
      axios
        .post("/api/database/resetPasswordConfirm", {
          code,
          newPassword,
        })
        .then(({ data }) => {
          unsetLoading(e);
          e.target.innerHTML = `<div class="px-p ff-bold lh-text uppercase">Password updated.<br><a href='/#/signin' class='underline'>Go to login page</a>`;
        })
        .catch((err) => setError(e, "Invalid password change"));
    };
    ///////////////////////////////
    // Sample authenticated page //
    ///////////////////////////////
    const authenticatedTemplate = (profile) => `
          <header>
            <div id="site-title" class="fs-large ff-pixel accent">Études<br>Archives Days</div>
            <div id="countdown" class="fs-large ff-text uppercase accent"></div>
            <div class="fs-large ff-pixel accent">Welcome!</div>
            <a id="link-overlay" href="${App.decryptString(
              App.redirectUrl
            )}" target="_self"></a>
            <a href="${App.decryptString(
              App.redirectUrl
            )}" id="accessLink" class="inline-block ff-text fs-large uppercase accent mt-1" target="_self">Access${buttonIcon}</a>
            <a href="#" id="logout" class="inline-block ff-text fs-large uppercase accent mt-1">Logout${buttonIcon}</a>
          </header>
          <div id="background-video">
            <video src="https://player.vimeo.com/external/436852469.hd.mp4?s=48a77de02bdc5d512a2c8ca47fa5394be0a32e02&profile_id=175" playsinline autoplay muted loop preload="auto" />
          </div>
        `;
    ////////////////////////////////////////
    // Auth & private routes requirements //
    ////////////////////////////////////////
    const privateRouteOptions = (router) => ({
      before: (done, params) => {
        const token = Cookies.get(cookieName);
        if (!token) {
          router.navigate("/");
        } else {
          verifyToken(token, ({ status }) => {
            if (status === "valid") {
              done();
            } else {
              // if token is stale,
              // delete current cookie
              // and redirect to home
              Cookies.remove(cookieName, {
                path: "/",
              });
              router.navigate("/");
            }
          });
        }
      },
      after: () => {
        handleLinks(router);
      },
    });
    const publicRouteOptions = (router) => ({
      before: (done, params) => {
        const token = Cookies.get(cookieName);
        if (token) {
          router.navigate("/authenticated");
        } else {
          done();
        }
      },
    });
    ////////////////////////
    // Token verification //
    ////////////////////////
    const verifyToken = (token, cb) =>
      axios
        .get(`/api/database/verify?token=${token}`)
        .then((response) => cb(response.data))
        .catch(cb);
    /////////////
    // WELCOME //
    /////////////
    const welcomeTemplate = `
          <header>
            <div id="site-title" class="fs-large ff-pixel">Études<br>Archives Days</div>
            <div id="time-left" class="fs-large ff-text uppercase white"></div>
            <div class="fs-large ff-pixel mt-1">Enter</div>
            <a id="link-overlay" href="${App.decryptString(
              App.redirectUrl
            )}" target="_self"></a>
          </header>
          ${false ? teaserWelcomeTemplate : ""}
        `;
    /////////////
    //  ENDED  //
    /////////////
    const endedTemplate = `
          <header>
            <div id="site-title" class="fs-large ff-pixel">Études<br>Archives Days</div>
            <div class="fs-large ff-text white uppercase">is finished</div>
            <div class="fs-large ff-pixel">See you next time!</div>
            <div class="inline-block ff-text fs-large uppercase accent mt-1">Exit${buttonIcon}</div>
            <a id="link-overlay" href="https://etudes-studio.com" target="_self"></a>
          </header>
        `;
    //////////////////////////
    // Initializer & router //
    //////////////////////////
    const app = document.querySelector("#app");
    const token = Cookies.get(cookieName);
    const root = null;
    const useHash = true;
    const hash = "#";
    if (App.mode == "teaser") {
      app.innerHTML = teaserTemplate;
      return;
    }
    Routes.router = new Navigo(root, { hash: true });
    Routes.router.hooks({
      after: (params) => {
        handleLinks(Routes.router);
        App.interact();
      },
    });
    Routes.router.on(
      "/",
      () => {
        app.innerHTML =
          Routes.mode === "archives-off" ? defaultTemplateOff : defaultTemplate;
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router.on(
      "/signin",
      () => {
        app.innerHTML = signinTemplate;
        document
          .querySelector("#signin")
          .addEventListener("submit", (e) => signin(e, Routes.router));
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router.on(
      "/signup",
      () => {
        app.innerHTML = signupTemplate;
        document
          .querySelector("#signup")
          .addEventListener("submit", (e) => signup(e, Routes.router));
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router.on(
      "/password",
      () => {
        app.innerHTML = passwordInitTemplate;
        document
          .querySelector("#passwordInit")
          .addEventListener("submit", (e) => passwordInit(e, Routes.router));
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router.on(
      "/password/confirm",
      () => {
        const params = window.location.hash.substr(1).split("?")[1];
        const code = parseQueryString(params).oobCode;
        app.innerHTML = passwordConfirmTemplate(code);
        document
          .querySelector("#passwordConfirm")
          .addEventListener("submit", (e) => passwordConfirm(e, Routes.router));
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router.on(
      "/welcome",
      () => {
        if (Countdown.finished) {
          app.innerHTML = welcomeTemplate;
        } else {
          Routes.router.navigate("/");
        }
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router.on(
      "/ended",
      () => {
        app.innerHTML = endedTemplate;
      },
      publicRouteOptions(Routes.router)
    );
    Routes.router
      .on(
        "/authenticated",
        () => {
          app.innerHTML = authenticatedTemplate();
        },
        privateRouteOptions(Routes.router, token)
      )
      .resolve();
  },
};
export default Routes;
