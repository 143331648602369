import App from '../App'

const ResponsiveVideo = {
    init: _ => {
        ResponsiveVideo.items = document.querySelectorAll('video.lazy:not(.lazyloaded)')
        if (!ResponsiveVideo.items.length) return
        ResponsiveVideo.items.forEach(v => {
          ResponsiveVideo.setSource(v)
          ResponsiveVideo.addEvents(v)
          v.classList.add('lazyload')
        })
      document.addEventListener('lazybeforeunveil', function(e){
          if (e.target.tagName === 'VIDEO') {
            if (ResponsiveVideo.current) {
              ResponsiveVideo.mute(ResponsiveVideo.current)
            }
            ResponsiveVideo.current = e.target
            ResponsiveVideo.playWithSound(e.target)
          }
      });
    },
    setSource: video => {
        if (!video) return
        if (App.isMobile) {
            video.src = video.dataset.sd ? video.dataset.sd : video.dataset.hd
        } else {
            video.src = video.dataset.hd ? video.dataset.hd : video.dataset.sd
        }
    },
    addEvents: video => {
        if (!video) return
        video.addEventListener('click', _ => {
          ResponsiveVideo.playWithSound(video)
        })
    },
    playWithSound: video => {
        if (!video) return
        if(video.muted) {
          ResponsiveVideo.unmute(video)
        } else {
          ResponsiveVideo.mute(video)
        }
        const playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then(function() {
                // Ok
            }).catch(function(error) {
                ResponsiveVideo.mute(video)
                video.play();
            });
        }
    },
    mute: video => {
        video.muted = true;
        video.parentNode.classList.add('video-is-muted')
    },
    unmute: video => {
        video.muted = false;
        video.parentNode.classList.remove('video-is-muted')
    },
};
export default ResponsiveVideo;
