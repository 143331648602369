import App from '../App.js'

const Loader = {
  isLoaded: false,
  node: null,
  init: _ => {
    Loader.node = document.getElementById("loader");
    Loader.loading();
  },
  loading: _ => {

  },
  loaded: _ => {
    if (Loader.node) Loader.node.remove();
  }
};

export default Loader;
