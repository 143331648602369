import moment from "moment-timezone";
import Routes from "./Routes";

const TimeLeft = {
  time: "2024-11-24 23:59:59",
  init: () => {
    TimeLeft.node = document.querySelector("#time-left");
    if (!TimeLeft.node) return;
    TimeLeft.eventTime = moment.tz(TimeLeft.time, "Europe/Paris").unix();
    TimeLeft.currentTime = moment().unix();
    TimeLeft.diffTime = TimeLeft.eventTime - TimeLeft.currentTime;
    TimeLeft.duration = moment.duration(
      TimeLeft.diffTime * 1000,
      "milliseconds"
    );
    TimeLeft.interval = 1000;
    if (TimeLeft.diffTime > 0) {
      // TimeLeft.moon = 65 - (1 - TimeLeft.diffTime/1010796) * 57
      TimeLeft.run();
      if (TimeLeft.intervalInstance)
        window.clearInterval(TimeLeft.intervalInstance);
      TimeLeft.intervalInstance = setInterval(TimeLeft.run, TimeLeft.interval);
    } else {
      TimeLeft.finished = true;
      Routes.router.navigate("/ended");
    }
  },
  run: () => {
    TimeLeft.duration = moment.duration(
      TimeLeft.duration.asMilliseconds() - TimeLeft.interval,
      "milliseconds"
    );
    var d = moment.duration(TimeLeft.duration).days(),
      h = moment.duration(TimeLeft.duration).hours(),
      m = moment.duration(TimeLeft.duration).minutes(),
      s = moment.duration(TimeLeft.duration).seconds();
    // let c = `${TimeLeft.format(d)}D ${TimeLeft.format(h)}H ${TimeLeft.format(m)}M ${TimeLeft.format(s)}S`;
    let c = `${
      d
        ? `<span class="number">${TimeLeft.format(
            d
          )}:<sup>Extra time</sup></span>`
        : ""
    }${`<span class="number">${TimeLeft.format(
      h
    )}:<sup></sup></span>`}${`<span class="number">${TimeLeft.format(
      m
    )}:<sup></sup></span>`}${`<span class="number">${TimeLeft.format(
      s
    )}<sup></sup></span>`}`;
    TimeLeft.node.innerHTML = c;

    TimeLeft.eventTime = moment.tz(TimeLeft.time, "Europe/Paris").unix();
    TimeLeft.currentTime = moment().unix();
    TimeLeft.diffTime = TimeLeft.eventTime - TimeLeft.currentTime;

    if (TimeLeft.diffTime > 0) {
    } else {
      TimeLeft.finished = true;
      Routes.router.navigate("/ended");
    }
  },
  format: (n) => {
    return ("0" + n).slice(-2);
  },
};
export default TimeLeft;
